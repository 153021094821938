// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.

window.$ = require('jquery');
window.jQuery = window.$;

import './facebook-share';
import './anchorScroll';
import './menu';
import './notesToggle';
import './notes';
import './imagePopup';

var sidebarAnchors = require('./sidebarScroll.js');
